export const LOAD_DATA_PROFILE = 'LOAD_DATA_PROFILE'
export const UPDATE_CAL_IPD = 'UPDATE_CAL_IPD'
export const UPDATE_DATE_UPDATE = 'UPDATE_DATE_UPDATE'


export const LOAD_FILTER_RECIPES = 'LOAD_FILTER_RECIPES'
export const CHANGE_CATEGORIES = 'CHANGE_CATEGORIES'
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const CHANGE_FILTER_INGREDIENTS = 'CHANGE_FILTER_INGREDIENTS'
export const CHANGE_MINMAX_KCAL = 'CHANGE_MINMAX_KCAL'
export const CHANGE_FILTER_NAME_RECIPE = 'CHANGE_FILTER_NAME_RECIPE'
export const CHANGE_FILTER_FAVORITES = 'CHANGE_FILTER_FAVORITES'

export const LOAD_DATA_RECIPES = 'LOAD_DATA_RECIPES'
export const LOAD_COUNT_RECIPES = 'LOAD_COUNT_RECIPES'
export const LOAD_MAX_KCAL = 'LOAD_MAX_KCAL'

export const SET_RECIPE_NUMBER = 'SET_RECIPE_NUMBER'
export const SET_RECIPE_NUM_SERVINGS = 'SET_RECIPE_NUM_SERVINGS'
export const SET_RECIPE_FAVORITE = 'SET_RECIPE_FAVORITE'
export const LOAD_DATA_RECIPE = 'LOAD_DATA_RECIPE'

export const SET_OPEN_MODAL_FIELD_MENU = 'SET_OPEN_MODAL_FIELD_MENU'
export const SET_FIELD_MENU = 'SET_FIELD_MENU'
export const SET_FIELD_MENU_CONSTRUCTOR = 'SET_FIELD_MENU_CONSTRUCTOR'
export const SET_ITEM_MENU = 'SET_ITEM_MENU'
export const SET_ITEM_MENU_CONSTRUCTOR = 'SET_ITEM_MENU_CONSTRUCTOR'

export const SET_LOCALE = 'SET_LOCALE'
export const SET_LOCATION = 'SET_LOCATION'

export const SET_MEAL_PLAN_LIST = 'SET_MEAL_PLAN_LIST'

export const LOAD_MENU_TEMPLATE = 'LOAD_MENU_TEMPLATE'
export const SET_NAME_TEMPLATE = 'SET_NAME_TEMPLATE'
export const SET_TYPE_TEMPLATE = 'SET_TYPE_TEMPLATE'
export const SET_LIST_ITEMS_TEMPLATE = 'SET_LIST_ITEMS_TEMPLATE'





